<template>
  <div class="fill-height py-3 py-md-10">
    <Loading :loading="isLoading"></Loading>
    <v-container>
      <div class="txt-head mb-5">My Orders</div>
      <v-tabs color="var(--bkk-darkblue)" v-model="currentTab">
        <v-tab
          v-for="(item, i) in tabHeader"
          :key="i"
          style="text-transform: unset"
        >
          {{ item.title }}
          <div class="ml-2 badge-count" v-if="item.quantity">{{ item.quantity }}</div>
        </v-tab>
      </v-tabs>

      <div class="my-5 px-3 py-4 header-option">
        <div class="d-inline mr-3">Show :</div>
        <SelectBox
            :items="filterOptions"
            item_text="text"
            item_value="value"
            style="display: inline-block"
        ></SelectBox>
      </div>

      <v-tabs-items v-model="currentTab">
        <v-tab-item>
          <OrderList
            v-for="(item, i) in orders"
            :key="i"
            :order-id="item.id"
            :order-number="item.reference_number"
            :product="JSON.parse(JSON.parse(item.cart).cart_items)"
            :checkout_status="item.checkout_status"
            :shipping_status="item.shipping_status"
          ></OrderList>
        </v-tab-item>
        <v-tab-item>
          <OrderList
              v-for="(item, i) in orders"
              :key="i"
              :order-id="item.id"
              :order-number="item.reference_number"
              :product="JSON.parse(JSON.parse(item.cart).cart_items)"
              :status="item.payment_transaction"
          ></OrderList>
        </v-tab-item>
        <v-tab-item>
          <OrderList
              v-for="(item, i) in orders"
              :key="i"
              :order-id="item.id"
              :order-number="item.reference_number"
              :product="JSON.parse(JSON.parse(item.cart).cart_items)"
              :status="item.payment_transaction"
          ></OrderList>
        </v-tab-item>
      </v-tabs-items>
      <div v-if="orders && orders.length <= 0" class="text-center grey--text">You still not make any order yet.</div>
    </v-container>
  </div>
</template>

<script>
import SelectBox from '../components/input/SelectBox'
import OrderList from '../components/OrderList'
import Loading from "@/components/Loading"
import { mapState } from 'vuex'

export default {
  name: 'MyOrders',
  components: {
    SelectBox,
    OrderList,
    Loading
  },
  data() {
    return {
      tabHeader: [
        {
          title: 'To be shipping',
          quantity: 0
        },
        {
          title: 'To receive',
          quantity: 0
        },
        {
          title: 'Complete',
          quantity: 0
        }
      ],
      filterOptions: [],
      currentTab: 0,
      isLoading: false
    }
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch('Order/onGetAllOrders', this.accessToken)
    this.isLoading = false
  },
  computed: {
    ...mapState({
      orders: state => state.Order.orders,
      accessToken: state => state.Auth.userDetail.access_token
    })
  }
}
</script>

<style scoped lang="scss">
.header-option {
  background: #f2f2f2;
}
.badge-count {
  color: #fff;
  background: #000;
  border-radius: 40%;
  padding: 0 7px;
}
</style>
