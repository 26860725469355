<template>
  <div class="order-layout mb-5">
    <div class="order-head pa-3">
      <v-layout justify-space-between>
        <v-flex shrink>ORDER : {{ orderNumber }}</v-flex>
        <v-flex shrink>
          <a :href="'/order/' + orderId">View More ></a>
        </v-flex>
      </v-layout>
    </div>
    <v-layout class="pa-3" justify-space-between v-for="(x, i) in product" :key="i">
      <v-flex xs6 class="d-inline-flex">
        <div>
          <v-img
            contain
            class="mr-4"
            width="100px"
            max-height="120px"
            :src="x.product.photos && x.product.photos[0] && x.product.photos[0].image_url ? x.product.photos[0].image_url : require('../assets/img/no-image.png')"
          ></v-img>
        </div>
        <div class="txt-detail">
          {{ x.product.name }}
        </div>
      </v-flex>
      <v-flex xs1>
        <div class="txt-detail">Qty: {{ x.quantity }}</div>
      </v-flex>
      <v-flex shrink>
        <div
            class="status-sign text-center txt-detail py-1 px-3"
            :class="{
              'red': checkout_status === 'CANCEL',
              'blue': checkout_status === 'WAITING',
              'green': checkout_status === 'CONFIRM'
            }"
        >{{ checkout_status }}</div>
      </v-flex>
      <v-flex xs1>
        <div class="txt-detail">{{ shipping_status }}</div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'OrderList',
  props: {
    orderId: {
      default: ''
    },
    orderNumber: {
      default: ''
    },
    product: {
      default: null
    },
    checkout_status: {
      default: ''
    },
    shipping_status: {
      default: ''
    }
  },
  created() {
    console.log(this.product)
  }
}
</script>

<style scoped lang="scss">
.order-layout {
  border: solid 2px #f2f2f2;
  .order-head {
    border-bottom: solid 2px #f2f2f2;
  }
}
.status-sign {
  opacity: .8;
  border-radius: 30px;
  color: #fff;
}
</style>
